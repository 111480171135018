<template>
  <dok-downdrop>
    <template v-slot:button>
      <div class="relative">
        <div
          style="
            width: 10px;
            height: 10px;
            background: #fd5b68;
            right: -2px;
            top: -2px;
          "
          v-show="unreadNotificationsCount"
          class="absolute rounded-full"
        ></div>
        <img src="/svg/notifications.svg" alt="notifications" />
      </div>
    </template>

    <template v-slot:content>
      <template v-if="!loading">
        <div class="flex justify-between mb-6">
          <h3 class="text-xl font-EffraR text-blackedsidebar">
            Notifications :
          </h3>
        </div>
        <div class="flex flex-col gap-y-4" v-if="notifications.length">
          <div
            class="
            border border-solid
            px-5
            pt-5
            pb-3
            rounded-lg
            relative
            cursor-pointer
          "
            style="border-color: #cbcbcb"
            v-for="notification in notifications"
            :key="notification._id"
            @click="markAsRead(notification)"
          >
            <div
              v-show="notification.status !== 'READ'"
              class="h-3 w-3 rounded-full bg-red-500 absolute top-0 right-0 m-3"
            ></div>
            <div class="flex flex-row gap-x-3">
              <img src="/svg/nvconsultation.svg" />
              <div class="flex flex-col gap-y-3">
                <div class="flex flex-row items-center gap-x-3">
                  <span class="flex flex-row items-center gap-x-1"
                    ><i class="far fa-calendar"></i>
                    {{
                      _moment()(notification.created_at).format("DD MMM")
                    }}</span
                  >
                  <span class="flex flex-row items-center gap-x-1"
                    ><i class="far fa-clock"></i>
                    {{
                      _moment()(notification.created_at).format("HH:mm")
                    }}</span
                  >
                  <span class="flex flex-row items-center gap-x-1"
                    ><i class="fas fa-stethoscope"></i>
                    {{ $notificationTraduction(notification.model) }}</span
                  >
                </div>
                <h3
                  class="font-EffraM text-sm text-black"
                  v-html="parseUrl(notification.body)"
                ></h3>
              </div>
            </div>

            <div class="flex justify-end font-EffraR text-gray-600">
              {{ dateFromNow(notification.created_at) }}
            </div>
          </div>
        </div>
        <div
          v-if="!notifications.length"
          class="flex flex-col gap-y-2 items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="83pt"
            height="83pt"
            viewBox="0 0 83 83"
            version="1.1"
          >
            <g id="surface1">
              <path
                style="
                fill: none;
                stroke-width: 3;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke: #dadada;
                stroke-opacity: 1;
                stroke-miterlimit: 4;
              "
                d="M 13.730422 21 C 13.554217 21.302711 13.302334 21.554593 12.998494 21.729669 C 12.694654 21.903614 12.350151 21.997364 12 21.997364 C 11.649849 21.997364 11.305346 21.903614 11.001506 21.729669 C 10.697666 21.554593 10.445783 21.302711 10.269578 21 M 21.999623 17.000377 L 2.000377 17.000377 C 2.795557 17.000377 3.559111 16.684111 4.120482 16.120482 C 4.684111 15.560241 5.000377 14.795557 5.000377 14.000377 L 5.000377 9 C 5.000377 7.143072 5.736822 5.362952 7.050452 4.050452 C 8.362952 2.736822 10.143072 2.000377 12 2.000377 C 13.856928 2.000377 15.637048 2.736822 16.949548 4.050452 C 18.263178 5.362952 18.999623 7.143072 18.999623 9 L 18.999623 14.000377 C 18.999623 14.795557 19.315889 15.559111 19.879518 16.120482 C 20.440889 16.684111 21.204443 17.000377 21.999623 17.000377 Z M 21.999623 17.000377 "
                transform="matrix(3.458333,0,0,3.458333,0,0)"
              />
            </g>
          </svg>

          <span style="color: #585858" class="text-base font-EffraR"
            >Il n'y a aucune notification pour le moment</span
          >
        </div>
        <dok-button
          size="md"
          @click.native="listNotifications(true)"
          v-if="loadMore"
          custom-class="w-full mt-2"
          >Voir plus</dok-button
        >
      </template>
      <template v-if="loading">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="
            margin: auto;
            background: #fff;
            display: block;
            shape-rendering: auto;"
          width="200px"
          height="200px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle cx="30" cy="50" fill="#fd5b68" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"
            ></animate>
          </circle>
          <circle cx="70" cy="50" fill="#2e6bff" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="0s"
            ></animate>
          </circle>
          <circle cx="30" cy="50" fill="#fd5b68" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"
            ></animate>
            <animate
              attributeName="fill-opacity"
              values="0;0;1;1"
              calcMode="discrete"
              keyTimes="0;0.499;0.5;1"
              dur="1s"
              repeatCount="indefinite"
            ></animate>
          </circle>
        </svg>
      </template>
    </template>
  </dok-downdrop>
</template>

<script>
import { NOTIFICATIONS_SUBSCRIBE } from "@/graphql/notifications";
import moment from "moment";
import { EventBus } from "@/event-bus";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      notifications: [],
      drop: false,
      unreadNotificationsCount: 0,
      lastNotification: null,
      paginate: { page: 1, count: 0 },
      loading: false,
      loadMore: true
    };
  },
  mounted() {
    this.listNotifications();
    this.getUnreadNotifications({
      onData: data => {
        this.unreadNotificationsCount = data.data;
      }
    });
  },
  methods: {
    ...mapActions({
      getNotifications: "doctor/GET_NOTIFICATIONS",
      markNotificationAsRead: "doctor/MARK_NOTIFICATION_AS_READ",
      getSingleNotification: "doctor/GET_NOTIFICATION",
      getInvite: "social/GET_INVITAION",
      getUnreadNotifications: "doctor/GET_UNREADED_NOTIFICATIONS"
    }),
    async listNotifications(loadMore = false) {
      if (loadMore) {
        this.paginate.page++;
        this.loading = true;
      }

      await this.getNotifications({
        page: this.paginate.page,
        onData: ({ ok, data }) => {
          if (ok) {
            if (!data.length) {
              this.loadMore = false;
            }

            this.notifications = loadMore
              ? [...this.notifications, ...data]
              : data;
          }
        }
      });

      this.loading = false;
    },
    _moment() {
      return moment;
    },
    dateFromNow(date) {
      return moment(date).fromNow();
    },
    markAsRead(notification) {
      if (notification.status === "READ") {
        return;
      }
      notification.status = "READ";
      this.markNotificationAsRead(notification._id);
      this.unreadNotificationsCount -= 1;
    },
    parseUrl: function(text) {
      let urlRegex = text.match(/(https?:\/\/[^\s]+)/g);
      if (urlRegex)
        return text.replace(/(https?:\/\/[^\s]+)/g, text => {
          return `<a href="${text}" class="hover:underline" target="_blank">Cliquez ici pour le visualiser.</a>`;
        });
      return text;
    },
    getNotification(notification) {
      if (notification.model === "VIDEO-CONSULTATION-REMINDER") {
        EventBus.$emit("isJoinToWaitingRoom");
        return;
      }
      const notificationId = notification.model_id;
      const lastNotification = localStorage.getItem("last-notification");
      if (lastNotification === notificationId) {
        return;
      }

      localStorage.setItem("last-notification", notificationId);
      if (notification.model.startsWith("invites")) {
        this.getInvite({
          id: notificationId,
          onData: ({ data: invite }) => {
            if (invite) {
              let user =
                invite.type === "EXTERNAL"
                  ? invite.recipient
                  : notification.model === "invites-canceled"
                  ? invite.senderId
                  : invite.recipientId;
              const title = {
                invites: "A new invitation",
                "invites-rejected": "Invitation reject",
                "invites-canceled": "Invitation canceled",
                "invites-accepted": "Invitation accepted"
              };
              this.$vs.notify({
                time: 8000,
                title: title[notification.model],
                text: `${user.title} ${user.firstName} ${user.lastName}`,
                color:
                  ["invites-accepted", "invites"].indexOf(
                    notification.model
                  ) !== -1
                    ? "success"
                    : "danger",
                iconPack: "feather",
                icon: "icon-check"
              });
            }
          }
        });
        return;
      }
      this.getUnreadNotifications({
        onData: data => {
          this.unreadNotificationsCount = data.data;
        }
      });
      this.getSingleNotification({
        notificationId,
        onData: ({ data: notification }) => {
          if (notification) {
            if (
              notification.model === "HEALTH-BOOK" &&
              notification.patientId
            ) {
              EventBus.$emit(
                "health-book-notification",
                notification.patientId
              );
            }
            this.$vs.notify({
              time: 8000,
              title: notification.title,
              text: this.parseUrl(notification.body),
              color: "success",
              iconPack: "feather",
              icon: "icon-check"
            });
            this.notifications.unshift(notification);
          }
        }
      });
    }
  },
  apollo: {
    $subscribe: {
      notifications: {
        query: NOTIFICATIONS_SUBSCRIBE,
        variables() {
          return {
            userId: this.$auth.getUserId()
          };
        },
        result({ data: { live_events } }) {
          // console.log("new notif", live_events);

          if (live_events.length) {
            this.getNotification(live_events[0]);
          }
        }
      }
    }
  }
};
</script>

<style></style>
